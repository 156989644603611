import React from "react"

import Layout, { withCenter } from "../components/layout"
import SEO from "../components/seo"
import { Box, Heading, Text } from "../components/responsiveComponents";
import { outerSpacing, twoColGrid } from "../theme/spacing";
import { dark, light } from "../theme/colors";
import { FieldLabel } from "../templates/projectDetail"
import { SectionTitle } from "../components/title";
import { ProjectList } from "../components/projects";
import { InternalLink } from "../components/link";
import { graphql } from "gatsby";

const InnerContainer = withCenter(Box)

const NotFoundPage = ({data}) => (
  <Layout>
    <SEO title="404: Not found" />
    <InnerContainer {...outerSpacing} {...dark}>
      <FieldLabel>Error: 404</FieldLabel>
      <Box py={"vert.2"} width={[1, 0.7]}>
        <Text fontSize={5}>Sorry. We can't seem to find what you're looking for.</Text>
      </Box>
      <Box my={"vert.2"}>
        <InternalLink color="primary.5" hovercolor="primary.6" to="/">Back To Home ></InternalLink>
      </Box>
    </InnerContainer>
    <InnerContainer {...outerSpacing} {...light}>
        <SectionTitle>Latest Projects</SectionTitle>        
        <ProjectList
            projects={data.related.nodes} 
            images={data.allProjectImages.nodes} 
            titleColor="neutral.0" 
            subtitleColor="neutral.1"
            childBoxProps={{
                ...twoColGrid,
                py: "vert.1",
                my: "vert.3",
            }}
            boxProps={{
                mt: "vert.5"
            }}
        />
        <InternalLink color="primary.4" my={"vert.2"} hovercolor="primary.6" to="/projects">See All Projects ></InternalLink>
      </InnerContainer>
  </Layout>
)
export const query = graphql`    
    query {      

    related: 
        allProjectsYaml(limit: 2) {
            nodes {
                name
                location
                slug
                imageFolder
            }
        }

    ...allProjectImages       
    
  }
`

export default NotFoundPage
